import * as React from "react"
import styled from "styled-components"
import FooterLink from "./FooterLink";
import {Container} from "../../Container"
import SubscribeForm from "./SubscribeForm";
import SendinBlueForm from "../SendinBlueForm";

const Footer = () => (
    <FooterWrapper id="footer">
        <FooterColumnContainer>
            <FooterColumn>
                <span>Product</span>
                <ul>
                    <li>
                      <FooterLink href="#download">Download</FooterLink>
                    </li>
                    <li>
                        <FooterLink href="https://support.ergowallet.io" target="_blank">Support</FooterLink>
                    </li>
                </ul>
            </FooterColumn>
            <FooterColumn>
                <span>Resources</span>
                <ul>
                    <li>Knowledge Base</li>
                    <li>Blog</li>
                    <li>
                        <FooterLink href="https://ergoscan.io" target="_blank">Blockchain Explorer</FooterLink>
                    </li>
                    <li>
                        <FooterLink href="https://ergoplatform.org?utm_source=ergowallet" rel="nofollow" target="_blank">Ergo Platform</FooterLink>
                    </li>
                </ul>
            </FooterColumn>
            <FooterColumn>
                <span>Company</span>
                <ul>
                    <li>About Us</li>
                    <li>Careers</li>
                    <FooterLink href={"/terms"}>Terms of Service</FooterLink>
                    <li>Privacy Policy</li>
                </ul>
            </FooterColumn>
            <FooterColumn>
                <span>Get Involved</span>
                <ul>
                    <li>
                        <FooterLink href='https://twitter.com/ErgoWallet' rel='nofollow' target='_blank'>Twitter</FooterLink>
                    </li>
                    <li>
                        <FooterLink href='https://github.com/ergowallet' rel='nofollow' target='_blank'>GitHub</FooterLink>
                    </li>
                    <li>
                        <FooterLink href='https://shop.spreadshirt.com/ergowallet/' target='_blank'>Swag Shop</FooterLink>
                    </li>
                </ul>
            </FooterColumn>
        </FooterColumnContainer>
        <SubscribeContainer>
            {/*<SubscribeForm />*/}
            {/*<SendinBlueForm />*/}
        </SubscribeContainer>
        {/*<BrandContainer>*/}
        {/*  <Logo>Ergo Wallet</Logo>*/}
        {/*</BrandContainer>*/}
    </FooterWrapper>
)


const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const Logo = styled.div`
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${props => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const SubscribeContainer = styled(Container)`
  padding-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`

const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${props => props.theme.font.bold};
    color: ${props => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${props => props.theme.font.normal};
      font-size: 15px;
    }
  }
`

export default Footer
