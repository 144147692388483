import * as React from "react"
import styled from "styled-components"

const LinkWrapper = styled.a`
  text-decoration: none;
`;

const LinkContainer = styled.div`
    margin-bottom: 14px
`;

const FormSubtitleLink = styled.a`
  color: ${props => props.theme.color.secondary};
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.color.secondary};
`

const FooterLink = (props: any) => {
    const {href, children, target, rel} = props;
    return (
        <LinkContainer>
            <FormSubtitleLink rel={rel} href={href} target={target}>{children}</FormSubtitleLink>
        </LinkContainer>

    );
};

export default FooterLink;
