import React from "react"
import styled from "styled-components"

import Section from "../Section"
import Container from "../Container";

const Features = () => (
  <Section id="features">
    <Container>
      <Subtitle>Features</Subtitle>
      <SectionTitle>Smart money management</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Non-custodial</FeatureTitle>
          <FeatureText>
            We do not store your keys. You have full control over your funds and on the associated private keys.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Standards supported</FeatureTitle>
          <FeatureText>
            Keys management according to BIP-39, BIP-32 Bitcoin standards.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Cross-Platform Desktop</FeatureTitle>
          <FeatureText>
            Ergo Wallet are supported on Windows, MacOS and Linux operating systems.
            A desktop wallet is an application that is installed directly on your computer.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Multi-asset wallet</FeatureTitle>
          <FeatureText>
            Supports native tokens on Ergo Platform.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Payments</FeatureTitle>
          <FeatureText>Send money to friends and family with ease.</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Deterministic wallet</FeatureTitle>
          <FeatureText>
            All keys are derived from a single seed. Easy backup and import whole tree of keys.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Unlimited accounting</FeatureTitle>
          <FeatureText>
            Manage any number of wallets with our hierarchical deterministic (HD) wallet implementation. Have more control over how your funds are organized. Use our powerful back-up and restoration features to recover your funds if necessary.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Open source</FeatureTitle>
          <FeatureText>
            Ergo Wallet is built on open-source technology, providing transparency and security for your digital assets. You can verify the integrity of our wallet, contribute to its development, and rest assured that your crypto holdings are in safe hands
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </Container>
  </Section>
)

export default Features

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
