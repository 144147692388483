import * as React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Section from "../Section"
import Container from "../Container";

const version = "0.1.13"

const GetStarted = () => (
  <StyledSection id='download'>
    <GetStartedContainer>
      <GetStartedTitle>Download latest Ergo Wallet</GetStartedTitle>
        <DownloadContainer>
            <DownloadLink>
                <FontAwesomeIcon fixedWidth={true} size="5x" icon={["fab", "linux"]}/>
                <a href={`https://github.com/ErgoWallet/ergowallet-desktop/releases/download/v${version}/ergo-wallet-linux-x86_64-${version}.AppImage`} target={"_blank"}>Linux</a>
            </DownloadLink>
            <DownloadLink>
                <FontAwesomeIcon fixedWidth={true} size="5x" icon={["fab", "apple"]}/>
                <a href={`https://github.com/ErgoWallet/ergowallet-desktop/releases/download/v${version}/ergo-wallet-mac-x64-${version}.dmg`} target={"_blank"}>MacOS</a>
            </DownloadLink>
            <DownloadLink>
                <FontAwesomeIcon fixedWidth={true} size="5x" icon={["fab", "windows"]}/>
                <a href={`https://github.com/ErgoWallet/ergowallet-desktop/releases/download/v${version}/ergo-wallet-win-x64-${version}.exe`} target={"_blank"}>Windows</a>
            </DownloadLink>
        </DownloadContainer>
    </GetStartedContainer>
  </StyledSection>
)

export default GetStarted

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`
const DownloadContainer = styled.div`
    display: flex;
`

const DownloadLink = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    a {
      color: ${props => props.theme.color.secondary};
      padding-bottom: 1px;
      text-decoration: none;
    }
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const TryItButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const Subtitle = styled.span`
  ${props => props.theme.font_size.xxsmall}
  padding-top: 16px;
  font-size: 14px;
  color: ${props => props.theme.color.primary};
`
