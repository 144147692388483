import * as React from 'react';
import styled from 'styled-components';

if (typeof window !== `undefined`) {
  (window as any).REQUIRED_CODE_ERROR_MESSAGE = 'Please choose a country code';
  (window as any).EMAIL_INVALID_MESSAGE = (window as any).SMS_INVALID_MESSAGE = "The information provided is invalid. Please review the field format and try again.";
  (window as any).REQUIRED_ERROR_MESSAGE = "This field cannot be left blank.";
  (window as any).GENERIC_INVALID_MESSAGE = "The information provided is invalid. Please review the field format and try again.";
  (window as any).translation = {
    common: {
      selectedList: '{quantity} list selected',
      selectedLists: '{quantity} lists selected'
    }
  };
}


var AUTOHIDE = Boolean(0);

const SibContainer = styled.div`
  text-align:center;
  background-color:rgba(255,255,255,1);
  max-width:540px;
  border-radius:3px;
  border-width:1px;
  border-color:#C0CCD9;
  border-style:solid;
`;

const EntryLabel = styled.label`
  font-size:16px;
  text-align:left;
  font-weight:700;
  font-family:&quot;Helvetica&quot;, sans-serif;
  color:#3c4858;
`;

const SibFormBlockButton = styled.button`
  font-size:16px;
  text-align:left;
  font-weight:700;
  font-family: "Helvetica", sans-serif;
  color:#FFFFFF;
  background-color:#3E4857;
  border-radius:3px;
  border-width:0px;
`;

const ErrorMessage = styled.div`
  font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d;
  background-color:#ffeded; border-radius:3px; border-color:#ff4949;max-width:540px;
`;

const SuccessMessage = styled.div`
  font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#085229;
  background-color:#e7faf0;
  border-radius:3px; border-color:#13ce66;
  max-width:540px;
`;

const SendinBlueFormWrapper = styled.div`
    padding: 0px !important;
    margin-left: 0px !important;
    width: 100% !important;
`;

const SubscribeFormWrapper = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  
  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const SibFormBlockButtonWrapper = styled.div`
  padding-top: 40px !important;
  @media (max-width: ${props => props.theme.screen.sm}) {
      padding-top: 8px  !important;
  }
`;

const SubscribeInput = styled.input`
  // font-weight: 500;
  // font-size: 16px;
  // color: ${props => props.theme.color.primary};
  // line-height: 42px;
  // width: 100%;
  // text-align: left;
  // height: 40px;
  // border-width: 1px;
  // border-style: solid;
  // border-color: ${props => props.theme.color.secondary};
  // border-image: initial;
  // border-radius: 4px;
  // padding: 8px 16px;
  // outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  // @media (max-width: ${props => props.theme.screen.md}) {
  //   margin-bottom: 8px;
  // }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const SendinBlueForm = (props: any) => {
  const formId = props.formId || 'sib-form';
  const postUri = "https://48629a4b.sibforms.com/serve/MUIEAEhncBqELVkF8YufNN8YQQk6F2NVW_GDpZLTa7iHtQtR0dmmwcKn6Rc6xKCzU4kzpNuPAgULTXoL01wNfTjqhYROhNlZzc8TXN8auQ2vDrwLlSpCYiSRtWsRBsAwdxhS0dqJFnGRJtYXi3ialEzvNZek3EoFvnGjuGg_LYYddPoyMGOYyQDLezfbfNIgqE7gPn-RMMWPbaUv";

  return (
    <SendinBlueFormWrapper className="sib-form">
      <div id="sib-form-container" className="sib-form-container">
        <ErrorMessage id="error-message" className="sib-form-message-panel"
          // style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#661d1d; background-color:#ffeded; border-radius:3px; border-color:#ff4949;max-width:540px;"
        >
          <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
            <svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
              <path
                d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z"
              />
            </svg>
            <span className="sib-form-message-panel__inner-text">
              Your subscription could not be saved. Please try again.
            </span>
          </div>
        </ErrorMessage>
        <SuccessMessage id="success-message" className="sib-form-message-panel"
          // style="font-size:16px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#085229; background-color:#e7faf0; border-radius:3px; border-color:#13ce66;max-width:540px;"
        >
          <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
            <svg viewBox="0 0 512 512" className="sib-icon sib-notification__icon">
              <path
                d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z"
              />
            </svg>
            <span className="sib-form-message-panel__inner-text">Your subscription has been successful.</span>
          </div>
        </SuccessMessage>
        <SibContainer id="sib-container" className="sib-container--large sib-container--vertical">
          <SubscribeFormWrapper
            id={formId}
            method="POST"
            action={postUri}
            data-type="subscription"
          >

            <div style={{padding: "8px 0"}}>
              <div className="sib-input sib-form-block">
                <div className="form__entry entry_block">
                  <div className="form__label-row ">
                    <EntryLabel className="entry__label" htmlFor="EMAIL" data-required="*">
                      Enter your email address to subscribe
                    </EntryLabel>

                    <div className="entry__field">
                      <SubscribeInput
                        className="input"
                        type="text"
                        id="EMAIL"
                        name="EMAIL"
                        autoComplete="off"
                        placeholder="EMAIL"
                        data-required="true"
                        required
                      />
                    </div>

                  </div>

                  <label className="entry__error entry__error--primary"></label>
                  {/*<label className="entry__specification"*/}
                  {/*       style="font-size:12px; text-align:left; font-family:&quot;Helvetica&quot;, sans-serif; color:#8390A4;"*/}
                  {/*>*/}
                  {/*    Provide your email address to subscribe. For e.g abc@xyz.com*/}
                  {/*</label>*/}
                </div>
              </div>
            </div>
            <SibFormBlockButtonWrapper className="sib-form-block">
              <SibFormBlockButton
                className="sib-form-block__button sib-form-block__button-with-loader"
                form="sib-form"
                type="submit"
              >
                <svg className="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
                     viewBox="0 0 512 512">
                  <path
                    d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z"
                  />
                </svg>
                SUBSCRIBE
              </SibFormBlockButton>
            </SibFormBlockButtonWrapper>

            <input type="text" name="email_address_check" className="input--hidden"/>
            <input type="hidden" name="locale" value="en"/>
          </SubscribeFormWrapper>
        </SibContainer>
      </div>
    </SendinBlueFormWrapper>
  );
}

export default SendinBlueForm;